@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/butler');
@import url('https://fonts.cdnfonts.com/css/gavency-free');

body{
    margin: 0%;
    padding: 0%;
    font-family: outfit;
}
.appbar {
    background: transparent !important;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in !important;
    box-shadow: none !important;
}

.appbar_bg {
    background: transparent !important;
    height: 100px;
    backdrop-filter: blur(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s ease-in !important;
}
.hide_scrollbar::-webkit-scrollbar {
    display: none;

}

.hide_scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.anime{
    overflow-x: hidden;
}

a{
    text-decoration: none;
}
.text_3{
    background-image: url('./assets/img/vectors/text3.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;

    padding-bottom: 12px;

}
.text_3_m{
    background-image: url('./assets/img/vectors/text3.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: center;

    padding-bottom: 10px;

}
.text{
    background: linear-gradient(90deg, #00DAFF 0%, #31BAFD 16%, #7190FA 38%, #A46EF7 59%, #C856F5 76%, #DF47F4 91%, #E742F4 100%);
    
        background-clip: text;
        -webkit-background-clip: text;
        /* For Safari */
        color: transparent;
}
.text_3_2{
    background: url('./assets/img/vectors/text3.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;

    /* padding-bottom: 24px; */
}
.text_2{
    background: url('./assets/img/vectors/text2.svg');
    background-repeat: no-repeat;
    background-position-y: top;

    padding-right: -10px;
}
.text_2_m{
    background: url('./assets/img/vectors/text2.svg');
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-size: 12px;
    

    /* margin-top: -10px; */
}
.text_1{
    background: url('./assets/img/vectors/text1.svg');
    background-repeat: no-repeat;
    background-position-x: right;

    padding-right: 70px;
}
.mobile{
   padding: 0px 50px;
   padding-bottom: 20px;
}
.text_3_mobile{
    background: url('./assets/img/vectors/text3.svg');
    background-repeat: no-repeat;
    background-position-y: bottom;
padding-left: 0%;
    padding-bottom: 20px;
}


.carousel {
    position: relative;
    width: 100%;
    height: 300px;
}

.carousel-images {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-image {
    display: none;
    /* background-size: contain; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* background-repeat: repeat-x; */
}

.carousel-image.active {
    display: block;
    animation: fade 1s ease-in-out;
}

.carousel-dots {
    position: absolute;
    top: 270px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    /* flex-direction: column; */
    gap: 5px;
    justify-content: center;
    align-items: center;
  
}

.carousel-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
   border:1px solid #fff;
    margin: 0 5px;
    cursor: pointer;
    transition: 0.5s all linear;

}

.carousel-dot.active {

    background-color: #fff;
}

.dark{
    background-color: #000;
    color:#fff !important
}
.light{
    background-color: #fff;
    color:#151515 !important
}
.text_dark{
    color: #fff;
}
.text_light{
        color: #151515
}

.two-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.three-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-block-content img {
    max-width: 100%;
    border-radius: 10px;
  align-items: center;
    margin-bottom: 20px;

}
.event_card:hover{
border:2px solid
}
@layer base{
    img{display: initial;}
}




/*       className= {
          darkMode ? "dark": "light"
      } */

      /* color= {
        darkMode ? "#F489FD": "primary"
    } */

    /*        color: darkMode ? "#fff" : "#8f8f8f", */